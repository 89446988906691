<template>
  <PageTemplate>
		<Section>
			<Card size="large">
				<CardSection>
					<Grid>
						<GridContainer size="6 4@md">
							<Images height="450px" width="100%" image="theme-sd/about1.jpg" />
						</GridContainer>
						<GridContainer size="6 4@md">
							<Images height="450px" width="100%" image="theme-sd/about2.jpg" />
						</GridContainer>
						<GridContainer size="12 4@md" align="middle">
							<Stack direction="column" align="left" space="3">
								<Heading size="1">our culture</Heading>
								<Heading size="4" color="primary" uppercase>there's nothing more beautiful than a confident woman who feels incredible in their own skin</Heading>
								<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Paragraph>
							</Stack>
						</GridContainer>
					</Grid>
				</CardSection>
			</Card>
			<Card size="large" color="secondary">
				<CardSection>
					<Stack direction="column" space="3">
						<Heading size="1" align="center">We run our business by our key beliefs.</Heading>
						<Grid>
							<GridContainer size="12 2@md"></GridContainer>
							<GridContainer size="12 4@md">
								<Heading size="4" color="primary">1. ALWAYS BE KIND</Heading>
								<Paragraph>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Paragraph>
							</GridContainer>
							<GridContainer size="12 4@md">
								<Heading size="4" color="primary">2. ALWAYS BE AUTHENTIC</Heading>
								<Paragraph>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Paragraph>
							</GridContainer>
							<GridContainer size="12 2@md"></GridContainer>
							<GridContainer size="12 2@md"></GridContainer>
							<GridContainer size="12 4@md">
								<Heading size="4" color="primary">3. ALWAYS CELEBRATE</Heading>
								<Paragraph>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Paragraph>
							</GridContainer>
							<GridContainer size="12 4@md">
								<Heading size="4" color="primary">4. ALWAYS HAVE A PLAN</Heading>
								<Paragraph>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Paragraph>
							</GridContainer>
							<GridContainer size="12 2@md"></GridContainer>
						</Grid>
						<Button label="Contact Us" color="primary" size="large" url="/sedona/contact" />
					</Stack>
				</CardSection>
			</Card>
			<Card size="large">
				<CardSection>
					<Stack direction="column" space="3" align="left">
						<Heading size="1">the team.</Heading>
						<Grid>
							<GridContainer size="6 3@md">
								<Stack direction="column" space="3">
									<Images height="300px" width="100%" image="theme-sd/about3.jpg" />
									<Stack direction="column" space="1">
										<Heading size="4" color="primary">JANE</Heading>
										<Paragraph micro>Owner</Paragraph>
									</Stack>
								</Stack>
							</GridContainer>
							<GridContainer size="6 3@md">
								<Stack direction="column" space="3">
									<Images height="300px" width="100%" image="theme-sd/about4.jpg" />
									<Stack direction="column" space="1">
										<Heading size="4" color="primary">SERRA</Heading>
										<Paragraph micro>Co-founder</Paragraph>
									</Stack>
								</Stack>
							</GridContainer>
							<GridContainer size="6 3@md">
								<Stack direction="column" space="3">
									<Images height="300px" width="100%" image="theme-sd/about5.jpg" />
									<Stack direction="column" space="1">
										<Heading size="4" color="primary">JENNIFER</Heading>
										<Paragraph micro>Specialist</Paragraph>
									</Stack>
								</Stack>
							</GridContainer>
							<GridContainer size="6 3@md">
								<Stack direction="column" space="3">
									<Images height="300px" width="100%" image="theme-sd/about6.jpg" />
									<Stack direction="column" space="1">
										<Heading size="4" color="primary">ELLY</Heading>
										<Paragraph micro>Customer Care</Paragraph>
									</Stack>
								</Stack>
							</GridContainer>
						</Grid>
					</Stack>
				</CardSection>
			</Card>

		</Section>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/projects/sedona/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>
